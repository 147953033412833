const slides = [
  "/images/BUSHMAN-CREATIVES-F21-0287.jpg",
  "/images/BUSHMAN-CREATIVES-F21-0188.jpg",
  "/images/BUSHMAN-CREATIVES-F21-0028.jpg",
  "/images/BUSHMAN-CREATIVES-F21-0287.jpg",
  "/images/BUSHMAN-CREATIVES-F21-0188.jpg",
  "/images/BUSHMAN-CREATIVES-F21-0028.jpg",
];

export default function ContactUs() {
  return (
    <main className="text-[#191919] space-y-12 sm:space-y-24">
      <section>
        <div className="overflow-y-hidden overflow-x-hidden relative sm:h-[250px] sm:min-h-[250px] h-[200px] min-h-[200px] flex items-center">
          <div className="caro-container">
            <div className="slider">
              <div className="slide-track">
                {slides.map((img, index) => (
                  <div
                    className="slide h-full w-full"
                    style={{ padding: "0" }}
                    key={index}>
                    <img loading="lazy" src={img} alt="" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="flex justify-center">
        <div className="w-11/12 sm:w-5/6">
          <div className="w-full sm:w-1/2">
            <h1 className="text-[24px] sm:text-[64px] text-center sm:text-start font-bold">
              Contact Us
            </h1>
            <p className="text-[14px] sm:text-[24px] text-center sm:text-start font-normal">
              Stay in touch to get more info on our Felabration 2023 Line Up
            </p>
            <form className="flex flex-col gap-y-6 sm:gap-y-12 my-12">
              <div className="flex flex-col gap-y-6 sm:gap-y-0 sm:flex-row justify-between flex-1">
                <div className="flex flex-col">
                  <label
                    htmlFor=""
                    className="text-[16px] sm:text-[24px] mb-3 font-normal">
                    First Name
                  </label>
                  <input
                    type="text"
                    placeholder="First Name"
                    className="border border-[#292D32B2] rounded-[5px] h-[44px] w-full sm:w-[265px] px-6"
                  />
                </div>
                <div className="flex flex-col">
                  <label
                    htmlFor=""
                    className="text-[16px] sm:text-[24px] mb-3 font-normal">
                    Last Name
                  </label>
                  <input
                    type="text"
                    placeholder="First Name"
                    className="border border-[#292D32B2] rounded-[5px] h-[44px] w-full sm:w-[265px] px-6"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor=""
                  className="text-[16px] sm:text-[24px] mb-3 font-normal">
                  Email
                </label>
                <input
                  type="text"
                  placeholder="info@felabration.net"
                  className="border border-[#292D32B2] rounded-[5px] h-[44px] w-full px-6"
                />
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor=""
                  className="text-[16px] sm:text-[24px] mb-3 font-normal">
                  Phone Number
                </label>
                <input
                  type="number"
                  className="border border-[#292D32B2] rounded-[5px] h-[44px] w-full px-6"
                />
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor=""
                  className="text-[16px] sm:text-[24px] mb-3 font-normal">
                  Message
                </label>
                <textarea
                  name=""
                  placeholder="Enter your mesaage"
                  id=""
                  className="border border-[#292D32B2] rounded-[5px] h-[200px] w-full p-6"></textarea>
              </div>
              <button className="w-full bg-gradient-to-r from-[#009A00] via-[#b9b345] to-[#FFD700] text-[16px] sm:text-[20px] font-semibold text-white rounded-[5px] py-3">
                Send message
              </button>
            </form>
          </div>
          <div className="w-full sm:w-1/2"></div>
        </div>
      </section>
      <section className="flex justify-center">
        <div className="w-11/12 sm:w-5/6 my-40 flex space-y-10 sm:space-y-0 justify-between flex-col sm:flex-row">
          {[
            {
              id: crypto.randomUUID(),
              heading: "Our Office",
              icon: "/icons/gas-station.svg",
              text: "N0 1, NERDC Rd, Agidingbi 101233, Ikeja, Lagos",
              para: "Visit Us",
            },
            {
              id: crypto.randomUUID(),
              heading: "Chat us",
              icon: "/icons/sms.svg",
              text: "info@felabration.net",
              para: "Chat us on",
            },
            {
              id: crypto.randomUUID(),
              heading: "",
              icon: "",
              text: "",
              para: "",
            },
          ].map(({ icon, text, para, heading }, index) => {
            return (
              <div
                className="flex flex-col items-start sm:max-w-xs gap-3"
                key={index}>
                {text && (
                  <img
                    loading="lazy"
                    src="/icons/gas-station.svg"
                    alt="gas station"
                  />
                )}
                <h3 className="font-medium text-[24px] sm:text-[32px]">
                  {heading}
                </h3>
                <p className="text-[16px]">{para}</p>
                {text.includes("@") ? (
                  <a
                    href={`mailto:${text}`}
                    className="text-[16px] sm:text-[20px]">
                    {text}
                  </a>
                ) : (
                  <p className="text-[16px] sm:text-[20px]">{text}</p>
                )}
              </div>
            );
          })}
        </div>
      </section>
    </main>
  );
}
