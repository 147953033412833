import { Carousel } from "../components/Carousel";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { BASE_URL } from "../utils/api";
import Song from "../components/Song";

export default function Home() {
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setAudios] = useState([]);
  const [songs, setSongs] = useState([]);
  const [toggle, setToggle] = useState(true);

  useEffect(() => {
    const getAlbums = async () => {
      // const res = await fetch(`${BASE_URL}/songs`);
      // const json = await res.json();
      // console.log(json);
      // setAudios(json.albums);
    };
    getAlbums();
  }, []);

  useEffect(() => {
    const getAllSongs = async () => {
      // const res = await fetch(`${BASE_URL}/songs`);
      // const json = await res.json();
      // setSongs(json.songs);
    };
    getAllSongs();
  }, []);

  return (
    <main className="space-y-12 sm:space-y-24">
      <div className="z-[5] relative">
        <section className=" flex flex-col text-center items-center justify-center">
          <h1 className="font-semibold text-[36px] sm:text-[76px] max-w-md sm:max-w-3xl leading-[43.02px] sm:leading-[93.48px]">
            Celebrating Afrobeat Culture at{" "}
            <span className="text-[#009A00] font-crimson relative inline-block">
              Felabration{" "}
              <img
                loading="lazy"
                alt="star"
                src="/icons/star.svg"
                className="absolute top-[-7px] right-[-18px] sm:top-[-19px] w-8 sm:w-auto sm:right-[-50px]"
              />
            </span>
          </h1>
          <p className="max-w-xs sm:max-w-[40rem] text-[16px] sm:text-[32px] mt-2 sm:mt-5">
            Celebrating the Afrobeat legend, Fela Anikulapo - Kuti at
            Felabration
          </p>
        </section>
      </div>
      <Carousel />
      <section className="flex flex-col justify-center mb-8 mt-[0]  sm:mb-20 sm:-mt-0 ">
        <div className="text-center w-11/12 sm:w-5/6 mx-auto flex items-center flex-col">
          <h2 className="text-[32px] leading-[44px] sm:leading-[96px] text-center sm:text-[64px] font-bold">
            Catch us Live
          </h2>
          <p className="text-[16px] sm:text-[32px] max-w-5xl">
            Felabration events and shows will be live on this space anytime you
            want to see what is happening in real time
          </p>
        </div>
        <div className="flex flex-col sm:gap-y-24 items-center my-12">
          <div className="flex gap-3 sm:gap-5 w-11/12 sm:w-5/6">
            <div className="flex flex-col gap-3 sm:gap-5">
              <div>
                <iframe
                  title="youtube"
                  className="rounded-[20px] w-[100%] h-[100%] min-h-[379px]"
                  src="https://www.youtube.com/embed/lbzGbL5310Q?autoplay=0&mute=1&loop=1&fs=0"></iframe>
              </div>
              <div className="flex justify-between gap-3 sm:gap-5">
                <div className="flex flex-col gap-3 sm:gap-5 sm:flex-row justify-between">
                  <div>
                    <iframe
                      title="youtube"
                      className="rounded-[20px] w-[173px] h-[155px] sm:w-[392px] sm:h-[342px]"
                      src="https://www.youtube.com/embed/ZyOUz2Xu3TU?autoplay=0&mute=1&loop=1&fs=0"></iframe>
                  </div>
                  <div>
                    <iframe
                      title="youtube"
                      className="rounded-[20px] w-[173px] h-[155px] sm:w-[392px] sm:h-[342px]"
                      src="https://www.youtube.com/embed/b3dR0JRMn3s?autoplay=0&mute=1&loop=1&fs=0"></iframe>
                  </div>
                </div>{" "}
                <div className="block sm:hidden">
                  <iframe
                    title="youtube"
                    className="rounded-[20px] w-[176px] h-[322.16px] sm:w-[418px] sm:h-[724px]"
                    src="https://www.youtube.com/embed/4v4g_he-UGU?autoplay=0&mute=1&loop=1&fs=0"></iframe>{" "}
                </div>
              </div>
            </div>
            <div className="hidden sm:block">
              <iframe
                title="youtube"
                className="rounded-[20px] w-[186px] sm:w-[418px] h-[322.16px] sm:h-[724px]"
                src="https://www.youtube.com/embed/4v4g_he-UGU?autoplay=0&mute=1&loop=1&fs=0"></iframe>
            </div>
          </div>
        </div>
      </section>
      <section className="flex flex-col items-center">
        <div className="text-center flex items-center flex-col">
          <h2 className="text-[32px] sm:text-[64px] font-bold">
            Felabration Competition
          </h2>
          <p className="text-[16px] sm:text-[32px] max-w-xs sm:max-w-3xl">
            Over the years felabration has birthed new projects apart from
            musical activities
          </p>
        </div>
        <div className="flex flex-col w-11/12 sm:w-5/6 sm:gap-y-24 items-center my-5 sm:my-12">
          <div className="flex justify-between sm:gap-28 flex-col-reverse sm:flex-row w-11/12 mx-auto sm:w-auto">
            <div className="sm:w-1/2 my-3 sm:my-0 flex flex-col justify-between">
              <h2 className="font-bold text-[24px] sm:text-[58px]">
                Felabration Event Calendar
              </h2>
              <p className="text-[14px] sm:text-[32px] font-normal max-w-3xl">
                Join us in celebrating the legacy of Fela Anikulapo Kuti with a
                series of exciting events! From dance and fashion competitions
                to debates and music concerts, there's something for everyone.
                Don't miss out on the fun and festivities!
              </p>
              <div className="flex sm:gap-20 justify-between my-8 sm:my-0">
                <div className="flex flex-col space-y-5">
                  <p className="text-[10px] sm:text-[16px] font-normal">Date</p>
                  <p className="text-[12px] sm:text-[20px] max-w-[10rem] sm:max-w-[15rem] font-medium leading-[25px]">
                    September 2024 - October 2024
                  </p>
                </div>
                <div className="p-[0.5px] bg-[#292D32]"></div>
                <div className="flex flex-col space-y-5">
                  <p className="text-[10px] sm:text-[16px] font-normal">
                    Venue
                  </p>
                  <p className="text-[12px] sm:text-[20px] max-w-[10rem] sm:max-w-[15rem] font-medium leading-[25px]">
                    Various Locations
                  </p>
                </div>
              </div>
              <div className="flex justify-center">
                <button
                  onClick={() => navigate("/competition")}
                  className="bg-gradient-to-r from-[#009A00] via-[#b9b345] to-[#FFD700] text-white font-medium text-[16px] sm:text-[24px] leading-[31.76px] sm:leading-[47.64px] rounded-full py-[6px] px-[31px] sm:px-[38px]">
                  See more
                </button>
              </div>
            </div>
            <div className="sm:w-1/2">
              <Link to={"/competition"}>
                <img
                  loading="lazy"
                  src="/images/felabration_competition.jpg"
                  className="rounded-[15px]"
                  alt="Felabration Event Calendar"
                />
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="flex justify-center">
        <div className="w-11/12 sm:w-5/6">
          <div>
            <h2 className="text-[32px] leading-[44px] sm:leading-[96px] text-center sm:text-[64px] font-bold">
              Felabration Through The Years
            </h2>
            <p className="text-[16px] leading-[24px] sm:leading-[48px] mx-auto text-center sm:text-[32px] font-normal max-w-5xl">
              Reminiscing over the years of celebrating felabration in grand
              style
            </p>
          </div>
          <div className="my-8">
            <div className="relative flex items-center justify-center">
              <img
                loading="lazy"
                alt="over the years"
                src="/images/over-the-years.jpg"
                className="h-[209px] rounded-3xl sm:h-[591px] object-cover w-full shadow-lg dark:shadow-black/30"
              />
              <button
                onClick={() => navigate("/competition")}
                className="absolute uppercase border z-50 py-2 px-4 sm:py-[13px] sm:px-[56px] rounded-full sm:leading-[47.64px] sm:text-[24px] font-bold text-white">
                See more
              </button>
              <div className="bg-[rgba(0,0,0,0.4)] rounded-3xl absolute top-0 left-0 w-full h-full"></div>
            </div>
          </div>
        </div>
      </section>
      <section className="flex justify-center">
        <div className="w-11/12 sm:w-5/6">
          <div className="space-y-5 sm:space-y-8">
            <h2 className="text-[32px] leading-[44px] sm:leading-[96px] sm:text-[64px] font-bold">
              Songs
            </h2>
            <div className="space-x-8">
              <button
                disabled={toggle}
                onClick={() => setToggle((state) => !state)}
                className={`${
                  toggle ? "bg-[#292D32] text-white" : "bg-[#B6FFB6]"
                } font-bold py-1 sm:py-[14px] px-5 sm:px-[29px] text-base sm:text-[20px] sm:leading-[20px] rounded-full`}>
                All
              </button>
              <button
                disabled={!toggle}
                onClick={() => setToggle((state) => !state)}
                className={`${
                  toggle ? "bg-[#B6FFB6]" : "bg-[#292D32] text-white"
                } font-bold py-1 sm:py-[14px] px-5 sm:px-[29px] text-base sm:text-[20px] sm:leading-[20px] rounded-full`}>
                Songs
              </button>
            </div>
          </div>
          <div className="my-6 sm:my-10 flex flex-col gap-4 sm:gap-10">
            {toggle
              ? songs &&
                songs.map((audio, index) => <Song key={index} audio={audio} />)
              : songs &&
                songs.map((audio, index) => <Song key={index} audio={audio} />)}
            <div className="flex justify-center my-8 sm:my-20">
              {/* <button className="font-medium text-[16px] sm:text-[24px] leading-[31.76px] sm:leading-[47.64px] border border-[#292D32] rounded-full py-[6px] px-[31px] sm:px-[38px]">
                See more
              </button> */}
              {!songs.length && (
                <div className="font-medium text-[16px] sm:text-[24px] leading-[31.76px] sm:leading-[47.64px] py-[6px] px-[31px] sm:px-[38px]">
                  No Music Available
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <section
        className="flex justify-center bg-[#009A00]"
        style={{ backgroundImage: "url('/images/noise-texture.svg')" }}>
        <div className="w-11/12 sm:w-5/6 gap-8 flex flex-col-reverse sm:flex-row sm:gap-0 my-8 sm:my-32">
          <div className="space-y-6 sm:space-y-10 sm:w-[60%]">
            <h2 className="text-white text-[32px] font-bold sm:text-[48px] leading-[48px] sm:leading-[72px]">
              Celebrating Afrobeat Culture at Felabration
            </h2>
            <p className="text-[16px] sm:text-[24px] leading-[26.8px] sm:leading-[40.2px] text-white font-normal">
              Felabration is an annual festival celebrating the life and legacy
              of Nigeria's iconic musician, Fela Anikulakpo Kuti. Conceived by
              Fela's eldest child, Yeni, in 1998, this week-long event takes
              place at the New Africa Shrine and attracts thousands of global
              visitors. Recognized as an official tourist attraction by the
              Lagos State Government, Felabration has hosted renowned
              international artists and features a diverse range of activities,
              including debates, dance competitions, art exhibitions, and more,
              all aimed at honoring Fela's profound contributions to society.
            </p>
            <p className="text-[16px] sm:text-[24px] leading-[26.8px] sm:leading-[40.2px] text-white font-normal">
              Fela Anikulakpo Kuti's enduring impact as the Afrobeat creator,
              social critic, and champion of the underprivileged continues to
              inspire millions. Felabration serves as a vibrant annual tribute,
              uniting music enthusiasts and cultural aficionados in
              commemorating Fela's remarkable influence on music, politics, and
              social change.
            </p>
          </div>
          <div className="sm:w-[40%]">
            <img src="/images/fela-image-2.svg" alt="" />
          </div>
        </div>
      </section>
      <section className="flex justify-center">
        <div className="w-11/12 sm:w-5/6 space-y-5 sm:space-y-12">
          <h2 className="text-[32px] leading-[44px] sm:leading-[96px] text-center sm:text-[64px] font-bold">
            Meet our sponsors
          </h2>
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-8 place-items-center w-full mx-auto">
            {[
              {
                id: crypto.randomUUID(),
                logo: "/logos/IMG-20241002-WA0006.jpg",
              },
              {
                id: crypto.randomUUID(),
                logo: "/logos/IMG-20241002-WA0007.jpg",
              },
              {
                id: crypto.randomUUID(),
                logo: "/logos/CHELSEA LOGO HI.jpg",
              },
              {
                id: crypto.randomUUID(),
                logo: "/logos/FEARLESS BLACK (2).png",
              },
              {
                id: crypto.randomUUID(),
                logo: "/logos/Sosa full logo.png",
              },
            
              {
                id: crypto.randomUUID(),
                logo: "/logos/Legend Logo2blk.png",
              },
              {
                id: crypto.randomUUID(),
                logo: "/logos/Logo copy.png",
              },
              {
                id: crypto.randomUUID(),
                logo: "/logos/IMG-20241001-WA0003.jpg",
              },
            ].map(({ logo, id }) => (
              <div
                key={id}
                className="flex justify-center items-center animate-bounce">
                <img
                  loading="lazy"
                  src={logo}
                  alt="sponsor"
                  className="w-24 sm:w-32 object-contain h-auto hover:scale-105 transition-transform duration-300"
                />
              </div>
            ))}
          </div>
          <div className="flex justify-center">
            <button
              onClick={() => navigate("/host-felabration")}
              className="bg-gradient-to-r from-[#009A00] via-[#b9b345] to-[#FFD700] text-white font-medium text-[16px] sm:text-[24px] leading-[31.76px] sm:leading-[47.64px] rounded-full py-[6px] px-[31px] sm:px-[38px]">
              See more
            </button>
          </div>
        </div>
      </section>
    </main>
  );
}

// [
//   {
//     id: crypto.randomUUID(),
//     logo: "/logos/7up logo (2).jfif",
//   },
//   {
//     id: crypto.randomUUID(),
//     logo: "/logos/SUPA_KOMANDO_LOGO_7-04 (3).png",
//   },
//   {
//     id: crypto.randomUUID(),
//     logo: "/logos/Legend Logo blk.png",
//   },
//   {
//     id: crypto.randomUUID(),
//     logo: "/logos/MoMo PSB logo - New direction fully written.jpg",
//   },
//   // new
//   {
//     id: crypto.randomUUID(),
//     logo: "/logos/Claymore.jpeg",
//   },
//   {
//     id: crypto.randomUUID(),
//     logo: "/logos/D' General.png",
//   },
//   {
//     id: crypto.randomUUID(),
//     logo: "/logos/shrine logo.jpg",
//   },
//   {
//     id: crypto.randomUUID(),
//     logo: "/logos/Kalakuta Museum.jpg",
//   },
//   {
//     id: crypto.randomUUID(),
//     logo: "/logos/HALLABET-LOGO-OUTLINE.png",
//   },
//   {
//     id: crypto.randomUUID(),
//     logo: "/logos/LSTAC LOGO_PNG_COLOURED.png",
//   },
//   {
//     id: crypto.randomUUID(),
//     logo: "/logos/FULL LOGO RED BG (2).png",
//   },
//   {
//     id: crypto.randomUUID(),
//     logo: "/logos/chelsea.jpeg",
//   },
//   {
//     id: crypto.randomUUID(),
//     logo: "/logos/golden-penny.png",
//   },
//   {
//     id: crypto.randomUUID(),
//     logo: "/logos/lag-tourism.jpeg",
//   },
// ];
